const countdown = (id, time) => {
	if (document.getElementById(id)) {
		const startDate = new Date(time).getTime()
		const interval = setInterval(() => {
			const now = new Date().getTime()
			const distance = startDate - now
			const d = Math.floor(distance / (1000 * 60 * 60 *24))
			const h = Math.floor(distance % (1000 * 60 * 60 *24) / (1000 * 60 * 60))
			const m = Math.floor(distance % (1000 * 60 * 60) / (1000 * 60))
			const s = Math.floor(distance % (1000 * 60) / (1000))
			let tag
			let stunde
			let minute
			let sekunde
	
			if (d < 1) { tag = "" }
			else { tag = d + " Tage" }
	
	
			if (h < 1) { stunde = "" }
			else if (h < 10) { stunde = '0' + h + 'h ' }
			else { stunde = h + "h " }
	
			if (m < 1) { minute = "" }
			else if (m < 10) { minute = '0' + m + 'm '}
			else { minute = m + "m " }
	
			if (s < 1) { sekunde = "" }
			else if (s < 10) { sekunde = '0' + s  + 's'}
			else { sekunde = s + 's'}
	
			const container = document.getElementById(id)

			container.innerHTML = `${tag} ${stunde}${minute}${sekunde}`
			
			const distanceMinutes = distance / 1000 / 60

			console.log(distanceMinutes);
			
			if (distanceMinutes < 29) {
				clearInterval(interval)
				window.location.reload()
			}
		 }, 1000)		
	}
 }