(function ($) {

    "use strict";

    // window.onscroll = function () {
	// 	if (!document.getElementById('chat')) {
	// 		stickyHeader()			
	// 	}
    // };

    // var header = document.getElementById("sticky-header");

    // var sticky = header.offsetTop;

    // function stickyHeader() {
    //     if (window.pageYOffset > sticky) {
    //         header.classList.add("background-header");
    //     } else {
    //         header.classList.remove("background-header");
    //     }
    // }

    window.sr = new scrollReveal();

    // Add smooth scrolling to all links
    $("a[href^=\"#\"]").on('click', function (event) {

        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 800, function () {

            // Add hash (#) to URL when done scrolling (default click behavior)
            window.location.hash = hash;
        });
    });
})(window.jQuery);